import React from "react"
import { navigate } from "gatsby"

// Maintaining this page in case of old links sent out with marketing material
const Quote = () => {
  if (typeof window !== `undefined`) {
    navigate(`/tenants/app/quote${window.location.search}`)
  }

  return <div />
}

export default Quote
